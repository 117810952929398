import {  useState } from 'react'
import { Link } from 'react-router-dom'
import AnimatedLetters from '../AnimatedLetters'
import { HashLink } from 'react-router-hash-link';
//import LogoTitle from '../../assets/images/logo-s.png'
import Logo from './Logo'
import './index.scss'
import Loader from 'react-loaders'
import disableScroll from 'disable-scroll';

const Home = () => {
  const [letterClass] = useState('text-animate')

  const nameArray = [' ','Emil',' ','Iuga']
  const jobArray = [
    'w',
    'e',
    'b',
    ' ',
    'developer',
    '.',
  ]
  disableScroll.on(); // prevent scrolling



  return (
    <>
    
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I'm</span>
            
           
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={22}
            />
          </h1>
          <h2>FullStack Developer/Generally funny guy</h2>
          <HashLink to="/contact" className="flat-button">
            CONTACT ME
          </HashLink>
         
        </div>
        <Logo />
      </div>

      <Loader type="pacman"/>
    </>
  )
}

export default Home
