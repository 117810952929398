import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import React, { useState } from 'react';
const Contact=()=>{
    const form=useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_gcgv8bw', 'template_y3yalv9', form.current, 'buBjgtWI5sCO8nfZ3')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          form.current.reset()
      };

      const [showPopup, setShowPopup] = useState(false);

      function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        if (form.checkValidity()) {
          setShowPopup(true);
        }
      }
      async function handleClick(e) {
        await handleSubmit(e);
        sendEmail(e);
        
      }
    return (
        <>
        <div className='container contact-page'>
            <div className='text-zone'>
                <h1>
                <AnimatedLetters
                strArray={['C','o','n','t','a','c','t']}
                idx={15}
                />
                </h1>
                <p>
                    Contact me about job oportunities or cool projects you have in mind.
                </p>
                <div className='contact-form'>
                    <form ref={form} onSubmit={handleClick}>
                        <ul>
                            <li className='half'>
                            <input type="text" name='name' placeholder='Name' required>
                            </input>
                            </li>
                            <li className='half'>
                            <input type="email" name='email' placeholder='Email' required>
                            </input>
                            </li>
                            <li >
                            <input placeholder="Subject" type="text" name="subject" required/>
                            </li>
                            <li>
                                <textarea placeholder='Message' name='message' required>

                                </textarea>
                            </li>
                            <li>
                                <input type="submit" className='flat-button' value="Send"></input>
                            </li>
                            
                        </ul>
                    </form>
                    {showPopup && <div className='popup' >Message sent!<br/>I will get back to you as soon as possible </div>}
                </div>
            </div>
        </div>
        <Loader type='pacman'></Loader>
        </>
    )
}

export default Contact
    
