import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngular, faCss3, faHtml5, faJava, faPython, faReact } from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'



const onButtonClick = () => {
  // using Java Script method to get PDF file
  fetch('Resume.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'Resume.pdf';
          alink.click();
      })
  })
}
const About =() => {
  
return (
  <>
    <div className='container about-page'>
        <div className='text-zone'>
            <h1>
                <AnimatedLetters
                strArray={['A','b','o','u','t',' ','m','e']}
                idx={15}
                />
                

            </h1>
            <p>Welcome to my personal website! My name is Emil Iuga and I am a software developer with experience in AngularJS, ReactJS, Python, and Java. I am constantly striving to expand my skillset and am currently diving into Linux. With a passion for coding and a drive to stay on top of the latest technologies, I am dedicated to creating efficient and user-friendly software solutions. Thank you for visiting my site and feel free to reach out to me with any questions or projects you may have.</p>
            <button class="flat-button" onClick={onButtonClick}>
                    Download Resume
                </button>
            <div className='stage-cube-cont'>
                <div className='cube'>
                <div className='cube-spinner'>
                <div className="face1">
              <FontAwesomeIcon icon={faAngular} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJava} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faPython} color="#EC4D28" />
            </div>
                </div>
            </div>
            </div>
        </div>


    </div>
    <Loader type="pacman"/>
    </>
)

}


export default About
