import { Link, NavLink } from 'react-router-dom'
import './index.scss'
import LogoS from '../../assets/images/Emil Iuga-logos_transparent2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope,faHome,faUser } from '@fortawesome/free-solid-svg-icons'
import {  faGitlab, faLinkedin } from '@fortawesome/free-brands-svg-icons'


const Sidebar = () =>{

    return(
    <div className='nav-bar'>
        <Link className='logo' to='/'>
            <img src ={LogoS} alt="logo"/>
            
        </Link>
    <nav>
       <NavLink exact='true' activeclassname="active" to="/">
        <FontAwesomeIcon icon={faHome} color="#4d4d4e"/>
        </NavLink> 
        <NavLink exact='true' activeclassname="active" className="about-link" to="/about">
        <FontAwesomeIcon icon={faUser} color="#4d4d4e"/>
        </NavLink> 
        <NavLink exact='true' activeclassname="active" className="contact-link" to="/contact">
        <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e"/>
        </NavLink> 
    </nav>
    <ul>
        <li>
            <a target="_blank" rel='noreferrer' href="https://www.linkedin.com/in/iuga-emil-4944961bb/"><FontAwesomeIcon icon={faLinkedin} color="#4d4d4e"></FontAwesomeIcon></a>
        </li>
        <li>
            <a target="_blank" rel='noreferrer' href="https://gitlab.com/EmilIuga"><FontAwesomeIcon icon={faGitlab} color="#4d4d4e"></FontAwesomeIcon></a>
        </li>
    </ul>
    </div>


    )

}



export default Sidebar